import React from "react";
import { Link } from "react-router-dom";
import "../css/nav.css";
import logo from "../content/Logo_nobg.png";

function Navbar() {
  return (
    <div className="nav-container">
      <div className="nav" id="header">
        <Link to="/" className="nav_logo">
          <img src={logo} alt="Logo" />
          <p>TomatenBot</p>
        </Link>
        <div className="nav_links">
          <a href="https://hypelist.net" target="_blank">
            Other projects
          </a>
          <Link to="/news">News</Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
