import { useEffect } from "react";

export const useDesc = (desc) => {
  useEffect(() => {
    const meta = document.querySelector('meta[name="description"]');
    if (meta) {
      meta.setAttribute("content", desc);
    } else {
      const newMeta = document.createElement("meta");
      newMeta.setAttribute("name", "description");
      newMeta.setAttribute("content", desc);
      document.head.appendChild(newMeta);
    }
  }, [desc]);

  return null;
};

export default useDesc;
