import React from "react";
import useDesc from "../hooks/useDesc";
import useKeywords from "../hooks/useKeywords";
import useTitle from "../hooks/useTitle";

function About() {
  useTitle("About the Project");
  useKeywords("About");
  useDesc(
    "About TomatenBot. TomatenBot once started as a small project for me andy my friends."
  );
  return (
    <div className="content">
      <div className="full-width flex-column">
        <h1>About the Project</h1>
        <h2>The Beginning</h2>
        <p>
          TomatenBot was a little project I worked on intended to be for me and
          my friend. Just having my own Discord Bot was a exciting thought. And
          as I wanted to learn Javascript, it was the perfect opportunity for
          me. I could customize the bot the way my friends and me wanted and
          needed it to be.
          <br />
          <br />I started the project as a little thing I did beside school,
          working on it on my free time, while obviously not neglecting my
          gaming-time :).
        </p>
        <h2>V13 of Discord.js came out</h2>
        <p>
          The bot was completely redone from the ground up, all the commands had
          been recoded and I really had fun trying out new stuff while doing so.
          <br />
          <br />I started trying to code a little more complicated stuff and
          that was the moment when the bot slowly became more than something
          just for my friends. After building on for a while, I kind of saw
          myself that the purpose of being just a bot for my friends and me,
          dissappeared slowly, as I removed more and more <i>very very weird</i>
          commands and starting to code more "serious" stuff.
          <br />
          <br />
          Then I decided that I want it to be something more. Something bigger.
          I knew it wasn't easy for me to achieve that, and it still isn't, as I
          still lack knowledge and need to learn wayyyyyyyy more.
        </p>
        <h2>What it is now</h2>
        <p>
          Well, currently the bot, and also everything else, like the website,
          need way more time and dedication to get to a point I would accept the
          bot being kind of "done". I'm learning so much because of that project
          and I don't plan to end it. When it is done, it would be more like the
          release of it, the end of a beta-phase, or something like that.
          <br />
          <br />
          I want the project to get as big as I can get it to be. And also as
          good. And I will do my best to improve it.
          <br />
          <i>~The dude working on this project</i>
        </p>
      </div>
    </div>
  );
}

export default About;
