import React from "react";

function NewsPost({ title, created, htmlContent }) {
  return (
    <div className="post">
      <h2>{title}</h2>
      <p>{created}</p>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
}

export default NewsPost;
