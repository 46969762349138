import React from 'react';
import useTitle from '../hooks/useTitle';
import error from "../css/error.module.css";

function Error() {
    useTitle("Oh ne, you're lost");

    return (
        <div className={error.content_error}>
        <div className={error.errdescription}>
          <img src="../content/Slime_jump.gif" style={{padding: '1em'}} alt="jumping slime" />
          <h1 classname={error.errh1}>This place seems sUs</h1>
          <p>...but dont worry <b className={error.errb}>Slimey mac slime slime</b> is coming for rescue</p>
          <a className={error.errbtn} id="backbtn" href="/">
            <h1 className={error.errh1}> Get rescued </h1>
          </a>
        </div>
      </div>
    );
}

export default Error;