import { useState, useEffect } from "react";
import PostForm from "../components/PostForm";

function AllPosts() {
  const [posts, setPosts] = useState([]);
  const [editingPost, setEditingPost] = useState(null);
  const [showPostForm, setShowPostForm] = useState(false);

  // Load all posts on component mount
  useEffect(() => {
    async function fetchPosts() {
      const res = await fetch("https://tomatenbot.com/api/news");
      const data = await res.json();
      const res2 = await fetch("https://tomatenbot.com/api/updates");
      const data2 = await res2.json();
      const fullData = data + data2;
      setPosts(fullData);
    }
    fetchPosts();
  }, []);

  function handleEditPost(post) {
    setEditingPost(post);
    setShowPostForm(true);
  }

  function handleAddPost() {
    setEditingPost(null);
    setShowPostForm(true);
  }

  function handlePostFormClose() {
    setShowPostForm(false);
  }

  function handlePostSubmit(newPost) {
    setShowPostForm(false);
    setEditingPost(null);

    // Update posts state with new post or edited post
    const newPosts = [...posts];
    const existingPostIndex = newPosts.findIndex((p) => p._id === newPost._id);
    if (existingPostIndex >= 0) {
      newPosts[existingPostIndex] = newPost;
    } else {
      newPosts.push(newPost);
    }
    setPosts(newPosts);
  }

  return (
    <div>
      <h1>All Posts</h1>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Created</th>
            <th>Content</th>
            <th>Main Version</th>
            <th>Sub Version</th>
            <th>Specification</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post) => (
            <tr key={post._id}>
              <td>{post.title}</td>
              <td>{new Date(post.Created).toLocaleDateString()}</td>
              <td>{post.html_content}</td>
              {post.mv ? <td>{post.mv}</td> : <></>}
              {post.sv ? <td>{post.sv}</td> : <></>}
              {post.specification ? <td>{post.specification}</td> : <></>}
              <td>
                <button onClick={() => handleEditPost(post)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddPost}>Add Post</button>
      {showPostForm && (
        <PostForm
          post={editingPost}
          onClose={handlePostFormClose}
          onSubmit={handlePostSubmit}
        />
      )}
    </div>
  );
}

export default AllPosts;
