import { useState } from "react";

function PostForm({ post, onSubmit }) {
  const [title, setTitle] = useState(post?.title || "");
  const [content, setContent] = useState(post?.html_content || "");
  const [mv, setMv] = useState(post?.mv || "");
  const [sv, setSv] = useState(post?.sv || "");
  const [specification, setSpecification] = useState(post?.specification || "");

  const handleSubmit = (event) => {
    event.preventDefault();
    var newPost = "";
    post
      ? (newPost = { title, html_content: content, mv, sv, specification })
      : (newPost = {
          title,
          html_content: content,
          mv,
          sv,
          specification,
          Created: Date.now(),
        });
    onSubmit(newPost);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Title:
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </label>
      <br />
      <label>
        Content:
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </label>
      <br />
      <label>
        Main Version:
        <input
          type="number"
          value={mv}
          onChange={(e) => setMv(e.target.value)}
        />
      </label>
      <br />
      <label>
        Sub Version:
        <input
          type="number"
          value={sv}
          onChange={(e) => setSv(e.target.value)}
        />
      </label>
      <br />
      <label>
        Specification:
        <input
          type="text"
          value={specification}
          onChange={(e) => setSpecification(e.target.value)}
        />
      </label>
      <br />
      <button type="submit">{post ? "Update" : "Add"} Post</button>
    </form>
  );
}

export default PostForm;
