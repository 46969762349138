import React from "react";
import useDesc from "../hooks/useDesc";
import useKeywords from "../hooks/useKeywords";
import useTitle from "../hooks/useTitle";
import "../css/legal.css";

function Legal() {
  useTitle("Privacy Policy and Terms of Use");
  useKeywords("Legal, Copyright, Privacy Policy, Terms of Use");
  useDesc("The Privacy Policy and Terms of Use for TomatenBot");
  return (
    <div class="content">
      <header>
        <h1>Legal Notice: TomatenBot</h1>
        <h2>Last updated: 22.2.2023</h2>
      </header>
      <nav>
        <div>
          <a href="#termsOfUse">
            <h3 className="margin-0">Terms of Use</h3>
          </a>
        </div>
        <div>
          <a href="#privacyPolicy">
            <h3 className="margin-top-0">Privacy Policy</h3>
          </a>
        </div>
      </nav>
      <nav>
        <div>
          <a className="navNum">1.</a>
          <a href="#welcome">Welcome!</a>
        </div>
        <div>
          <a className="navNum">2.</a>
          <a href="#whoAreWe">Who are we</a>
        </div>
        <div>
          <a className="navNum">3.</a>
          <a href="#yourAccount">Your Account</a>
        </div>
        <div>
          <a className="navNum">4.</a>
          <a href="#copyright">Copyright</a>
        </div>
        <div>
          <a className="navNum">5.</a>
          <a href="#informationYouProvide">Information you provide us</a>
        </div>
        <div>
          <a className="navNum">6.</a>
          <a href="#usageOfYourInformation">Usage of your Information</a>
        </div>
        <div>
          <a className="navNum">7.</a>
          <a href="#changesToThisLegalNotice">Changes to this Legal Notice</a>
        </div>
      </nav>
      <article>
        <div id="welcome">
          <h3>Welcome!</h3>
          <p>
            When we say "us", "we" or "our" we refer to TomatenBot and the
            people maintaining / working on the project.
            <br />
            <br />
            When we say "service" or "services" we mean the services connected
            to TomatenBot.
            <br />
            <br />
            When we refer to "you" or "your" we mean you.
            <br />
            <br />
            This Legal Notice serves firstly as an agreement between you the
            user and us and secondly states what data we collect and what we do
            with it.
            <br />
            <br />
            By using our website and any connected service you understand and
            accept the terms of this document.
          </p>
        </div>
        <div id="whoAreWe">
          <h3>Who are we</h3>
          <p>
            So far "we" are a one man team consisting of me, Lucjan Lubomski,
            operating in Austria, Vienna.
          </p>
        </div>
        <div id="yourAccount">
          <h2 id="termsOfUse">Terms of Use</h2>
          <h3>Your Account</h3>
          <p>
            If you are in possession of your own account for one of our
            services, you are responsible to keep your account safe and secure.
            If you believe your account has been compromised you agree on
            contacting us immediately. If you use a password, it must be strong,
            and we (strongly) recommend that you use that password only for this
            account.
            <br />
            <br />
            You are responsible for keeping your contact information, associated
            with your account, up to date. If you get locked out of your account
            and fail to provide proof via email or phone number, that the
            account belongs to you, we are unable to restore your account.
            <br />
            <br />
            While using our services you agree on using them within the laws of
            your home country and/or the European Union.
            <br />
            <br />
            You agree not to license, sell, or transfer your account without our
            prior written approval.
            <br />
            <br />
            We reserve ourself the right to remove/modify/inspect your account
            without the need of permission if we think its necessary.
            <br />
            <br />
            You always* have the right to terminate your own account. You are
            free to stop using our services and request a termination of your
            account, by contacting us.
            <br />* incase of a violation against this Legal Notice, we reserve
            ourself the right to disable and keep your account stored
            separately.
          </p>
        </div>
        <div id="copyright">
          <h3>Copyright</h3>
          <p>
            We respect the intellectual property of others and expect our users
            to do the same.
          </p>
        </div>
        <div id="informationYouProvide">
          <h2 id="privacyPolicy">Privacy Policy</h2>
          <h3>Information you provide us</h3>
          <i>Your privacy matters to us!</i>
          <p />
          <h4>Information you provide us:</h4>
          <ul>
            <li>
              <strong>Information of your actions:</strong>
              <br />
              <br />
              We store information with your use of our services.
            </li>
          </ul>
          <p />
          <p />
          <h4>Information we gather automatically:</h4>
          <ul>
            <li>
              <strong>Information about your device:</strong>
              <br />
              <br />
              We collect information about your device you are using to access
              our services. We also collect information like your IP address and
              browser information.
            </li>
            <br />
            <li>
              <strong>Information about your use of our website:</strong>
              <br />
              <br />
              We keep log on how and when you use our services.
            </li>
          </ul>
          We may receive information from cookies (small text files placed on
          your computer or device) and similar technologies. First-party cookies
          are placed by us (and our third-party service providers) and allow you
          to use the services and to help us analyze and improve your experience
          and the services. Cookies we use include the following:
          <ul>
            <li>
              <strong>Google Analytics:</strong>
              <br />
              <br />
              Collects information about the origin of the request and the users
              behavior on our website.
            </li>
            <br />
            <li>
              <strong>Cloudflare:</strong>
              <br />
              <br />
              Collects information, maximizes network resources, manages traffic
              and protects our website from malicious traffic.
            </li>
          </ul>
          <p />
        </div>
        <div id="usageOfYourInformation">
          <h3>Usage of Information</h3>
          <i>We are not selling your information!</i>
          <p />
          <h4>How we use Information:</h4>
          <ul>
            <li>
              <strong>To provide you with our services:</strong>
              <br />
              <br />
              Your information is used so you can access your account.
            </li>
            <br />
            <li>
              <strong>To contact you:</strong>
              <br />
              <br />
              We may use contact information to establish communication with
              you.
            </li>
            <br />
            <li>
              <strong>To provide customer service:</strong>
              <br />
              <br />
              We use information to respond to your questions about our
              services.
            </li>
            <br />
            <li>
              <strong>To protect our services:</strong>
              <br />
              <br />
              We use information to keep our services secure, to prevent misuse
              and to enforce our Legal Notice against users who violate them.
            </li>
            <br />
            <li>
              <strong>To improve our services:</strong>
              <br />
              <br />
              We use information to improve our features by understanding the
              way you interact with them.
            </li>
          </ul>
          <p />
          <p />
          <h4>How we share Information</h4>
          <ul>
            <li>
              <strong>To comply with the law:</strong>
              <br />
              <br />
              We may share information in response to a request for information
              if we believe disclosure is required by law.
            </li>
            <br />
            <li>
              <strong>In an emergency:</strong>
              <br />
              <br />
              We may share information if we believe in good faith that it's
              necessary to prevent serious harm to a person.
            </li>
            <br />
            <li>
              <strong>To enforce our policies and rights:</strong>
              <br />
              <br />
              We may share information if needed to enforce our Terms of
              Service, Community Guidelines or other policies or to protect the
              rights, property, and safety of ourselves and others.
            </li>
          </ul>
          <p />
        </div>
        <div id="changesToThisLegalNotice">
          <h3>Changes to this Legal Notice</h3>
          <p>
            We will update this Legal Notice from time to time. We always
            indicate the date the last changes were published. In case there are
            any significant changes, we will notify our users via email and over
            our services.
          </p>
        </div>
      </article>
    </div>
  );
}

export default Legal;
