import React from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";
import useScript from "../hooks/useScript";

function Footer() {
  useScript("https://unpkg.com/website-carbon-badges@1.1.3/b.min.js", true);

  return (
    <footer>
      <div id="wcb" class="carbonbadge wcb-d"></div>
      <Link to="contact">Contact</Link>
      <br />
      <br />
      <Link to="about">About</Link>
      <br />
      <br />
      <Link to="/legal">Legal Notice</Link>
      <p id="cr">Copyright© 2023 Lucjan Lubomski. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
