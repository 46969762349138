import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useTitle from "../hooks/useTitle";

function Auth(props) {
  const navigate = useNavigate();
  useTitle("Authenticating");

  useEffect(() => {
    const fragment = window.location.hash.substring(1);
    const parameters = new URLSearchParams(fragment);
    const accessToken = parameters.get("access_token");

    if (accessToken) {
      localStorage.setItem("login", true);
      localStorage.setItem("aT", accessToken);
      if (props.dst === "Dashboard") {
        navigate("/dashboard");
      } else if (props.dst === "Chat") {
        navigate("/chat");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="content">
      <p>We are processing the request...</p>
    </div>
  );
}

export default Auth;
