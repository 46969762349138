import React, { useState, useEffect } from "react";
import useTitle from "../hooks/useTitle";
import useKeywords from "../hooks/useKeywords";
import useDesc from "../hooks/useDesc";
import { Link } from "react-router-dom";
import Feature from "../components/Feature";

import Aos from "aos";
import "aos/dist/aos.css";

function Home() {
  useTitle("TomatenBot - A Customizable Multipurpose Discord Bot");
  useKeywords(
    "Discord, Bot, TomatenBot, Tomato, Tomaten, Fun, Moderation, Customizable"
  );
  useDesc(
    "TomatenBot is your friendly, highly customizable, multi purpose Discord Bot. With Tomatoes :)"
  );

  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    Aos.init({
      once: true,
      duration: 1500,
    });
    const checkToken = async (token) => {
      if (token) {
        try {
          const response = await fetch("https://discord.com/api/users/@me", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            return setIsTokenValid(true);
          } else {
            return;
          }
        } catch (error) {
          console.error(error);
          return;
        }
      }
    };
    checkToken(localStorage.getItem("aT"));
  }, []);

  return (
    <>
      <div className="content">
        <div className="full-width flex-column">
          <h1 id="gA76Nhfgx7dsq" className="margin-0">
            TomatenBot - A Customizable Multipurpose Discord Bot
          </h1>
          <h2 id="titleafter">It's Just a little bot I'm working on</h2>
          <p class="description">
            TomatenBot is your friendly, highly customizable, multi purpose
            Discord Bot.
            <br />
            With Tomatoes :)
          </p>
        </div>
        <p className="info">
          <b>The Tomatenbot team wishes you a happy birthday, Karol! 🥳🎉</b>
          <br />
          <br />
          This website is under construction.
          <br />
          <i>All features of the bot and the site are in progress.</i>
        </p>
        <div className="flex-row-com">
          <Link
            className="btn "
            id="lbtn"
            to={
              isTokenValid
                ? "/dashboard"
                : "https://discord.com/api/oauth2/authorize?client_id=845639573245526027&redirect_uri=https%3A%2F%2Ftomatenbot.com%2Fauth&response_type=token&scope=identify%20guilds"
            }>
            <div className="login_content" id="lC">
              {!isTokenValid && (
                <svg
                  width={30}
                  height={35}
                  viewBox="0 0 30 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.51429 0.416748H26.4857C28.4229 0.416748 30 1.98841 30 3.93591V34.5834L26.3143 31.3376L24.24 29.4242L22.0457 27.3913L22.9543 30.5517H3.51429C1.57714 30.5517 0 28.9801 0 27.0326V3.93591C0 1.98841 1.57714 0.416748 3.51429 0.416748ZM18.7371 21.3438C19.2514 21.993 19.8686 22.7276 19.8686 22.7276C23.6571 22.608 25.1143 20.1309 25.1143 20.1309C25.1143 14.6301 22.6457 10.1713 22.6457 10.1713C20.1771 8.32633 17.8286 8.37758 17.8286 8.37758L17.5886 8.65091C20.5029 9.53925 21.8571 10.8205 21.8571 10.8205C20.0743 9.84675 18.3257 9.36841 16.6971 9.1805C15.4629 9.04383 14.28 9.078 13.2343 9.21466C13.1445 9.21466 13.0678 9.22768 12.9813 9.24236C12.9687 9.24449 12.9559 9.24666 12.9429 9.24883C12.3429 9.30008 10.8857 9.52216 9.05143 10.3251C8.41714 10.6155 8.04 10.8205 8.04 10.8205C8.04 10.8205 9.46286 9.47091 12.5486 8.58258L12.3771 8.37758C12.3771 8.37758 10.0286 8.32633 7.56 10.1713C7.56 10.1713 5.09143 14.6301 5.09143 20.1309C5.09143 20.1309 6.53143 22.608 10.32 22.7276C10.32 22.7276 10.9543 21.9588 11.4686 21.3097C9.29143 20.6605 8.46857 19.2938 8.46857 19.2938C8.46857 19.2938 8.64 19.4134 8.94857 19.5842C8.96571 19.6013 8.98286 19.6184 9.01714 19.6355C9.04286 19.6526 9.06857 19.6654 9.09429 19.6782C9.12 19.691 9.14571 19.7038 9.17143 19.7209C9.6 19.9601 10.0286 20.148 10.4229 20.3017C11.1257 20.5751 11.9657 20.8484 12.9429 21.0363C14.2286 21.2755 15.7371 21.3609 17.3829 21.0534C18.1886 20.9167 19.0114 20.6776 19.8686 20.3188C20.4686 20.0967 21.1371 19.7722 21.84 19.3109C21.84 19.3109 20.9829 20.7117 18.7371 21.3438ZM10.1487 16.6458C10.1487 15.6037 10.9201 14.7495 11.8973 14.7495C12.8744 14.7495 13.663 15.6037 13.6458 16.6458C13.6458 17.6878 12.8744 18.542 11.8973 18.542C10.9373 18.542 10.1487 17.6878 10.1487 16.6458ZM16.4058 16.6458C16.4058 15.6037 17.1773 14.7495 18.1544 14.7495C19.1315 14.7495 19.903 15.6037 19.903 16.6458C19.903 17.6878 19.1315 18.542 18.1544 18.542C17.1944 18.542 16.4058 17.6878 16.4058 16.6458Z"
                    fill="white"></path>
                </svg>
              )}
              <h2>{isTokenValid ? "Go to Dashboard" : "Login with Discord"}</h2>
            </div>
          </Link>
          <Link
            className="btn"
            to="https://discord.com/api/oauth2/authorize?client_id=845639573245526027&permissions=8&scope=bot%20applications.commands">
            <div className="login_content" id="add">
              <svg
                width={30}
                height={35}
                viewBox="0 0 30 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.51429 0.416748H26.4857C28.4229 0.416748 30 1.98841 30 3.93591V34.5834L26.3143 31.3376L24.24 29.4242L22.0457 27.3913L22.9543 30.5517H3.51429C1.57714 30.5517 0 28.9801 0 27.0326V3.93591C0 1.98841 1.57714 0.416748 3.51429 0.416748ZM18.7371 21.3438C19.2514 21.993 19.8686 22.7276 19.8686 22.7276C23.6571 22.608 25.1143 20.1309 25.1143 20.1309C25.1143 14.6301 22.6457 10.1713 22.6457 10.1713C20.1771 8.32633 17.8286 8.37758 17.8286 8.37758L17.5886 8.65091C20.5029 9.53925 21.8571 10.8205 21.8571 10.8205C20.0743 9.84675 18.3257 9.36841 16.6971 9.1805C15.4629 9.04383 14.28 9.078 13.2343 9.21466C13.1445 9.21466 13.0678 9.22768 12.9813 9.24236C12.9687 9.24449 12.9559 9.24666 12.9429 9.24883C12.3429 9.30008 10.8857 9.52216 9.05143 10.3251C8.41714 10.6155 8.04 10.8205 8.04 10.8205C8.04 10.8205 9.46286 9.47091 12.5486 8.58258L12.3771 8.37758C12.3771 8.37758 10.0286 8.32633 7.56 10.1713C7.56 10.1713 5.09143 14.6301 5.09143 20.1309C5.09143 20.1309 6.53143 22.608 10.32 22.7276C10.32 22.7276 10.9543 21.9588 11.4686 21.3097C9.29143 20.6605 8.46857 19.2938 8.46857 19.2938C8.46857 19.2938 8.64 19.4134 8.94857 19.5842C8.96571 19.6013 8.98286 19.6184 9.01714 19.6355C9.04286 19.6526 9.06857 19.6654 9.09429 19.6782C9.12 19.691 9.14571 19.7038 9.17143 19.7209C9.6 19.9601 10.0286 20.148 10.4229 20.3017C11.1257 20.5751 11.9657 20.8484 12.9429 21.0363C14.2286 21.2755 15.7371 21.3609 17.3829 21.0534C18.1886 20.9167 19.0114 20.6776 19.8686 20.3188C20.4686 20.0967 21.1371 19.7722 21.84 19.3109C21.84 19.3109 20.9829 20.7117 18.7371 21.3438ZM10.1487 16.6458C10.1487 15.6037 10.9201 14.7495 11.8973 14.7495C12.8744 14.7495 13.663 15.6037 13.6458 16.6458C13.6458 17.6878 12.8744 18.542 11.8973 18.542C10.9373 18.542 10.1487 17.6878 10.1487 16.6458ZM16.4058 16.6458C16.4058 15.6037 17.1773 14.7495 18.1544 14.7495C19.1315 14.7495 19.903 15.6037 19.903 16.6458C19.903 17.6878 19.1315 18.542 18.1544 18.542C17.1944 18.542 16.4058 17.6878 16.4058 16.6458Z"
                  fill="white"></path>
              </svg>
              <h2>Add to Discord</h2>
            </div>
          </Link>
        </div>
        <h2>Why u should use TomatenBot</h2>
        <div className="full-width flex-column">
          <Feature
            orientation="start"
            title="Customization"
            text="Customize the bot to how you want and how you need it. Change messages, change the behaviour of the bot and much more."
            imagesrc="/content/Logo_nobg.png"
            imagealt="Customization showcase"
          />
          <Feature
            orientation="end"
            title="Moderation"
            text="Help your server and your moderators with our moderation features."
            imagesrc="/content/Logo_nobg.png"
            imagealt="Moderation showcase"
          />
          <Feature
            orientation="start"
            title="20+ Commands"
            text="Even though 20 is not really a high number, I am still working on everything and I will be adding many more commands and features to the bot."
            imagesrc="/content/Logo_nobg.png"
            imagealt="Commands showcase"
          />
          <Feature
            orientation="end"
            title="More than just a bot"
            text="Set sail to the Tomato Universe. Many more projects will be all connected together and TomatenBot is one of them."
            imagesrc="/content/Logo_nobg.png"
            imagealt="Network showcase"
          />
        </div>
      </div>
      <div className="bannerContainer flex-column full-width">
        <a href="https://maxijaxi.net" target="_blank">
          <img
            className="banner"
            src="/content/Banner_mxnetwork_v1.png"
            alt="TomatenBot x mxNetwork"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="300"></img>
        </a>
      </div>
    </>
  );
}

export default Home;
