import React from "react";

function Server(props) {
  return (
    <div>
      <div className="guild">
        <p className="servername">{props.name}</p>
        <img
          src={props.icon}
          className="servericon"
          alt={`Icon of ${props.name}`}></img>
      </div>
    </div>
  );
}

export default Server;
