import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./hooks/scrollToTop";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import Legal from "./pages/Legal";
import About from "./pages/About";
import Commands from "./pages/Commands";
import AllPosts from "./pages/AllPosts";
import Chat from "./pages/Chat";

/*
//#region Sentry
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://6b64441bb6d025f406ad0c9487aea23e@o4506656031834112.ingest.sentry.io/4506656036225024",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/tomatenbot\.com\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

//#endregion
*/

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="chat" element={<Chat />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="news" element={<News />} />
          <Route path="commands" element={<Commands />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="legal" element={<Legal />} />
          <Route path="auth" element={<Auth dst="Dashboard" />} />
          <Route path="chatauth" element={<Auth dst="Chat" />} />
          <Route path="posts" element={<AllPosts />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log());
