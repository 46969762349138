import { useEffect } from "react";

export const useCss = (url) => {
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = url;
    document.body.appendChild(link);

    return () => {
      document.body.removeChild(link);
    };
  }, [url]);
};

export default useCss;
