import React, { useState, useEffect, useRef } from "react";
import useTitle from "../hooks/useTitle";
import useDesc from "../hooks/useDesc";
import { AiOutlineSend } from "react-icons/ai";
import { Link } from "react-router-dom";

import "../css/chat.css";

function Chat() {
  useTitle("Chat feature alpha");
  useDesc("This is an alpha feature");

  const ref = useRef(null);

  const [user, setUser] = useState({});
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  const sendMessage = async () => {
    const input = document.getElementById("input");
    input.setAttribute("active", "false");

    if (input.value == "") return input.setAttribute("active", "true");

    const message = input.value;

    //Show a sending tooltip or implement animation?
    if (socket && socket.readyState === WebSocket.OPEN) {
      const messageObject = {
        text: message,
        timestamp: Date.now(),
      }; // Include timestamp or any other necessary data
      socket.send(JSON.stringify(messageObject));
    }
  };

  const loadMessages = (isRecon) => {
    const accessToken = localStorage.getItem("aT");

    fetch("https://tomatenbot.com/api/chat/messages", {
      headers: { at: accessToken },
    })
      .then((response) => {
        console.log("Fetch-messages-response" + response);
        if (!response) {
          return [];
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (!isRecon) {
          data.forEach((message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
          });
        } else if (isRecon) {
          const newMessageList = new Array();
          data.forEach((message) => newMessageList.push(message));
          setMessages(newMessageList);
        }
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      });
  };

  const connectWebSocket = () => {
    const accessToken = localStorage.getItem("aT");

    const newSocket = new WebSocket(
      `wss://tomatenbot.com?token=${accessToken}`
    );
    setSocket(newSocket);

    newSocket.addEventListener("message", (event) => {
      console.log("Received message");
      console.log(event);
      console.log(event.data);
      let newMessage = String;
      try {
        console.log("parsing json");
        newMessage = JSON.parse(event.data);
      } catch (err) {
        console.log("not json data, getting raw value");
        newMessage = event.data;
      }

      const input = document.getElementById("input");

      if (newMessage.status === "success") {
        //Show message is sent succesfully
        input.value = "";
        return input.setAttribute("active", "true");
      } else if (newMessage.error) {
        //Show message couldn't be sent
        //Not deleting message in input field for user to be able to retry
      } else if (newMessage.connection === "success") {
        return;
      } else {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });

    newSocket.addEventListener("close", (event) => {
      console.log("WebSocket closed with code:", event.code);

      // Check if closure is due to inactivity or tabbing out. Seems to be 1006 always.
      if (event.code === 1006) {
        // Establish a new WebSocket connection
        connectWebSocket();
        //Load messages that could have been sent while WebSocket connection closed
        loadMessages(true);
      }
    });
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("aT");

    fetch("https://discord.com/api/users/@me", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          setIsTokenValid(true);
          response.json().then((json) => {
            setUser(json);
          });
        }
      })
      .then(() => {
        connectWebSocket();
        loadMessages();
      });

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (messages.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages.length]);

  return (
    <div className="content chat-content">
      <div className="full-width flex-column">
        <div className="chat-user flex-row full-width">
          {isTokenValid ? (
            <img
              className="chat-avatar"
              alt="your avatar"
              src={
                user &&
                user.id &&
                user.avatar &&
                `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.jpg`
              }
            />
          ) : (
            <></>
          )}
          {isTokenValid ? (
            <p className="chat-name">{`${user.username}`}</p>
          ) : (
            <p className="chat-name">{`You need to log in`}</p>
          )}
        </div>
        <div className="chat-messages full-width flex-column">
          {isTokenValid ? (
            messages.map(function (message) {
              return (
                <div
                  className={
                    user &&
                    message.user &&
                    `chat-message ${
                      message.user.id === user.id
                        ? "chat-msg-own"
                        : "chat-msg-other"
                    } flex-column`
                  }
                  key={message.date}>
                  <div className="chat-upper flex-row">
                    <div className="chat-timestamp flex-row">
                      <p className="chat-message-timestamp">
                        {new Date(message.date).toLocaleString("default", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </p>
                    </div>
                    <div className="chat-message-user flex-row">
                      <img
                        className="chat-msg-user-avatar"
                        alt="your avatar"
                        src={
                          message.user && message.user.avatar
                            ? `https://cdn.discordapp.com/avatars/${message.user.id}/${message.user.avatar}.jpg`
                            : "https://cdn.discordapp.com/embed/avatars/0.png"
                        }
                      />
                      <p className="chat-name">
                        {message.user && `${message.user.username}`}
                      </p>
                    </div>
                  </div>
                  <div className="chat-message-content">
                    <p>{message.text}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <Link
              className="btn "
              id="lbtn"
              to="https://discord.com/api/oauth2/authorize?client_id=845639573245526027&response_type=token&redirect_uri=https%3A%2F%2Ftomatenbot.com%2Fchatauth&scope=identify+guilds">
              <div className="login_content" id="lC">
                <svg
                  width={30}
                  height={35}
                  viewBox="0 0 30 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.51429 0.416748H26.4857C28.4229 0.416748 30 1.98841 30 3.93591V34.5834L26.3143 31.3376L24.24 29.4242L22.0457 27.3913L22.9543 30.5517H3.51429C1.57714 30.5517 0 28.9801 0 27.0326V3.93591C0 1.98841 1.57714 0.416748 3.51429 0.416748ZM18.7371 21.3438C19.2514 21.993 19.8686 22.7276 19.8686 22.7276C23.6571 22.608 25.1143 20.1309 25.1143 20.1309C25.1143 14.6301 22.6457 10.1713 22.6457 10.1713C20.1771 8.32633 17.8286 8.37758 17.8286 8.37758L17.5886 8.65091C20.5029 9.53925 21.8571 10.8205 21.8571 10.8205C20.0743 9.84675 18.3257 9.36841 16.6971 9.1805C15.4629 9.04383 14.28 9.078 13.2343 9.21466C13.1445 9.21466 13.0678 9.22768 12.9813 9.24236C12.9687 9.24449 12.9559 9.24666 12.9429 9.24883C12.3429 9.30008 10.8857 9.52216 9.05143 10.3251C8.41714 10.6155 8.04 10.8205 8.04 10.8205C8.04 10.8205 9.46286 9.47091 12.5486 8.58258L12.3771 8.37758C12.3771 8.37758 10.0286 8.32633 7.56 10.1713C7.56 10.1713 5.09143 14.6301 5.09143 20.1309C5.09143 20.1309 6.53143 22.608 10.32 22.7276C10.32 22.7276 10.9543 21.9588 11.4686 21.3097C9.29143 20.6605 8.46857 19.2938 8.46857 19.2938C8.46857 19.2938 8.64 19.4134 8.94857 19.5842C8.96571 19.6013 8.98286 19.6184 9.01714 19.6355C9.04286 19.6526 9.06857 19.6654 9.09429 19.6782C9.12 19.691 9.14571 19.7038 9.17143 19.7209C9.6 19.9601 10.0286 20.148 10.4229 20.3017C11.1257 20.5751 11.9657 20.8484 12.9429 21.0363C14.2286 21.2755 15.7371 21.3609 17.3829 21.0534C18.1886 20.9167 19.0114 20.6776 19.8686 20.3188C20.4686 20.0967 21.1371 19.7722 21.84 19.3109C21.84 19.3109 20.9829 20.7117 18.7371 21.3438ZM10.1487 16.6458C10.1487 15.6037 10.9201 14.7495 11.8973 14.7495C12.8744 14.7495 13.663 15.6037 13.6458 16.6458C13.6458 17.6878 12.8744 18.542 11.8973 18.542C10.9373 18.542 10.1487 17.6878 10.1487 16.6458ZM16.4058 16.6458C16.4058 15.6037 17.1773 14.7495 18.1544 14.7495C19.1315 14.7495 19.903 15.6037 19.903 16.6458C19.903 17.6878 19.1315 18.542 18.1544 18.542C17.1944 18.542 16.4058 17.6878 16.4058 16.6458Z"
                    fill="white"></path>
                </svg>
                <h2>Login with Discord</h2>
              </div>
            </Link>
          )}
          <div className="chat-spacer full-width"></div>
          <div ref={ref} />
        </div>
        <div className="chat-input-container full-width">
          <div className="chat-input">
            <input
              id="input"
              disabled={!isTokenValid}
              type="text"
              className="chat-inputfield"
              placeholder={
                isTokenValid ? "message:" : "Login to write"
              }></input>
            <AiOutlineSend onClick={sendMessage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
