import React, { useState, useEffect } from "react";
import Command from "../components/Command";

const Commands = () => {
  const [commands, setCommands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch commands from the backend and update the state with the result
    async function fetchData() {
      const result = await fetch("https://tomatenbot.com/api/commands");
      const commands = await result.json();
      setCommands(commands);
    }

    fetchData();
  }, []);

  const filteredCommands = commands.filter(
    (command) =>
      command.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      command.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="content">
      <div className="full-width flex-column">
        <input
          type="text"
          placeholder="Search for a command"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        <div>
          {filteredCommands.map((command) => (
            <Command
              key={command.name}
              name={command.name}
              description={command.description || null}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Commands;
