import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

function Feature(props) {
  useEffect(() => {
    Aos.init({
      once: true,
      duration: 2000,
    });
  }, []);

  return (
    <div className={`flex-row-${props.orientation}`}>
      <div
        className="feature-container"
        data-aos="fade-up"
        data-aos-once="true">
        <div className="feature-width-handler">
          <div className={`feature feature-${props.orientation}`}>
            <div className="feature-description">
              <h3>{props.title}</h3>
              <p>{props.text}</p>
            </div>
            <img
              className="feature-image"
              src={props.imagesrc}
              alt={props.imagealt}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
