import React, { useState, useEffect } from "react";
import useTitle from "../hooks/useTitle";
import useCss from "../hooks/useCss";
import Server from "../components/Server";
import useDesc from "../hooks/useDesc";

const getIcon = (icon, id) => {
  if (icon != null) {
    return `https://cdn.discordapp.com/icons/${id}/${icon}.png?size=128`;
  } else {
    return "../content/Logo.png";
  }
};

const handleLogout = () => {
  localStorage.clear();
  window.location.href = "/";
};

function Dashboard() {
  useTitle("TomatenBot Dashboard - Everything in one place");
  useDesc(
    "Have everything you need in one compact Dashboard. Manage servers and more."
  );
  useCss("../css/dashboard.css");

  const [guilds, setGuilds] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    const accessToken = localStorage.getItem("aT");
    const fetchGuilds = async () => {
      const response = await fetch("https://tomatenbot.com/api/guilds", {
        headers: { at: accessToken },
      });
      const json = await response.json();
      setGuilds(json);
    };

    const fetchUser = async () => {
      const response = await fetch("https://discord.com/api/users/@me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const json = await response.json();
      setUser(json);
    };
    fetchUser();
    fetchGuilds();
  }, []);

  return (
    <div className="content">
      <div className="dashboard">
        <div id="user">
          <p id="name">{`${user.username}#${user.discriminator}`}</p>
          <img
            id="avatar"
            alt="your avatar"
            src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.jpg`}
          />
        </div>
        <div className="description">
          <div className="guilds_container">
            <p id="server">
              Your Servers(
              <span id="serverct">{guilds.length}</span>
              ):
              <br />
              <br />
            </p>
            <div id="guilds" className="guilds">
              {guilds.map((guild) => (
                <Server
                  key={guild.id}
                  name={guild.name}
                  icon={getIcon(guild.icon, guild.id)}
                />
              ))}
            </div>
          </div>
        </div>
        <button id="lbtn" class="btn" type="button" onClick={handleLogout}>
          <div className="login_content" id="lC">
            <h2>Logout of Dashboard</h2>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Dashboard;
