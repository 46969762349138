import React, { useState, useEffect } from "react";
import useTitle from "../hooks/useTitle";
import useKeywords from "../hooks/useKeywords";
import useDesc from "../hooks/useDesc";
import Submenu from "../components/Submenu";
import NewsPost from "../components/NewsPost";
import UpdatePost from "../components/UpdatePost";
import "../css/posts.css";

function News() {
  const [activeIndex, setActiveIndex] = useState(0); // 0 is news, 1 is updates
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [mainVersionFilters, setMainVersionFilters] = useState([]);

  const submenuLabels = ["News", "Updates"];

  useTitle("News and Updates");
  useKeywords("News, TomatenBot, Changes, Updates");
  useDesc("News and updates for the Discord Bot and other projects.");

  useEffect(() => {
    setLoading(true);
    const apiUrl =
      activeIndex == 0
        ? "https://tomatenbot.com/api/news"
        : "https://tomatenbot.com/api/updates";
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setPosts(data))
      .finally(() => setLoading(false));
  }, [activeIndex]);

  const filteredPosts = posts.filter((post) => {
    if (searchTerm && !post.title.includes(searchTerm)) {
      return false;
    }
    if (mainVersionFilters.length && !mainVersionFilters.includes(post.mv)) {
      return false;
    }
    return true;
  });

  const postComponents = filteredPosts.map((post) => {
    if (activeIndex == 0) {
      return (
        <NewsPost
          key={post.id}
          title={post.title}
          created={new Date(post.Created).toLocaleDateString()}
          htmlContent={post.html_content}
        />
      );
    } else {
      return (
        <UpdatePost
          key={post.id}
          title={post.title}
          created={new Date(post.Created).toLocaleDateString()}
          htmlContent={post.html_content}
          mv={post.mv}
          sv={post.sv}
          specification={post.specification}
        />
      );
    }
  });

  const mainVersions = [...new Set(posts.map((post) => post.mv))];
  const mainVersionFiltersJsx = mainVersions.map((mv) => {
    const subVersions = posts
      .filter((post) => post.mv === mv)
      .map((post) => post.sv)
      .filter((sv) => sv != null);
    return (
      <div key={mv}>
        <div
          onClick={() =>
            setMainVersionFilters((prevFilters) => {
              if (prevFilters.includes(mv)) {
                return prevFilters.filter((filter) => filter !== mv);
              } else {
                return [...prevFilters, mv];
              }
            })
          }>
          {`v${mv}`}
          {subVersions.length > 0 && <span>&nbsp;&#x25bc;</span>}
        </div>
        {subVersions.length > 0 && mainVersionFilters.includes(mv) && (
          <div>
            {subVersions.map((sv) => (
              <div key={`${mv}.${sv}`}>
                <label>
                  <input
                    type="checkbox"
                    checked={false} // TODO: implement filtering for sub versions
                    onChange={() => {}}
                  />
                  {`v${mv}.${sv}`}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      <div className="flex-column">
        <h1 className="margin-bot-0">News and Updates</h1>
      </div>
      <Submenu
        labels={submenuLabels}
        setState={setActiveIndex}
        activeIndex={activeIndex}
      />
      <input
        type="text"
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
      />
      <div>
        <div>Filter by main version:</div>
        {mainVersionFiltersJsx}
      </div>
      <div className="content">
        <div className="full-width flex-column">
          <ul className="full-width flex-column">{postComponents}</ul>
          <a href="https://rollercoin.com/?r=lc5bhctg" target="_blank">
            <img src="/content/w970h90.png" alt="970h90" />
          </a>
        </div>
      </div>
    </>
  );
}

export default News;
