import React from "react";
import useTitle from "../hooks/useTitle";
import useKeywords from "../hooks/useKeywords";
import useDesc from "../hooks/useDesc";
import "../css/contact.css";

function Contact() {
  useTitle("How to contact us");
  useDesc(
    "Write us an email to contact or support@tomatenbot.com. Or check ot our social media!"
  );
  useKeywords("contact");
  return (
    <div className="content">
      <div className="full-width flex-column">
        <h1>How to contact us</h1>
        <div className="flex-row-com contact-container">
          <div className="contact-type">
            <h2>Write an email</h2>
            <div className="full-width flex-column">
              <div>
                <h3>For business inquiries or other</h3>
                <a href="mailto:contact@tomatenbot.com" target="_blank">
                  contact@tomatenbot.com
                </a>
              </div>
              <div>
                <h3>For support</h3>
                <a href="mailto:support@tomatenbot.com" target="_blank">
                  support@tomatenbot.com
                </a>
              </div>
            </div>
          </div>
          <div className="contact-type">
            <h2>Our Social Media</h2>
            <div className="flex-row">
              <a href="https://discord.com" target="_blank" className="social">
                <img src="./content/icon_clyde_white_RGB.svg"></img>
              </a>
              <a href="https://twitter.com" target="_blank" className="social">
                <img src="./content/twitter_social_white.svg"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
