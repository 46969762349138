import React from "react";

function UpdatePost({ title, created, htmlContent, mv, sv, specification }) {
  return (
    <div className="post">
      <h2>{title}</h2>
      <p>{created}</p>
      <p>
        Version: v{mv}.{sv}
      </p>
      <p>Specification: {specification}</p>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
}

export default UpdatePost;
