import React from "react";

const Command = (props) => (
  <div>
    <h3>{props.name}</h3>
    {props.description && <p>{props.description}</p>}
  </div>
);

export default Command;
