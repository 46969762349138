import React from "react";
import "../css/submenu.css";

/**
 * Submenu component that displays a set of items and updates the state of the parent component
 * when an item is clicked.
 * @param {Array} labels - An array of strings that represent the labels to display on each item.
 * @param {Function} setState - A function that takes a state setter from the parent component.
 * @param {Number} activeIndex - The index of the currently active item.
 */
function Submenu({ labels, setState, activeIndex }) {
  const labelCount = labels.length;
  const itemWidth = `${100 / labelCount}%`;

  const firstIndex = 0;
  const lastIndex = labels.length - 1;

  const items = labels.map((label, index) => (
    <div
      key={index}
      className={`submenu-item ${index === activeIndex ? " active" : ""} ${
        index === firstIndex ? "first" : ""
      } ${index === lastIndex ? "last" : ""}`}
      style={{ width: itemWidth }}
      onClick={() => setState(index)}>
      <p>{label}</p>
    </div>
  ));

  return <div className="submenu">{items}</div>;
}

export default Submenu;
